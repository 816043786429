import { observer } from 'mobx-react';
import { useDependency } from '../../../injections';
import { Controller } from '../../../mesh/controller';


import Scrollbar from 'src/components/Scrollbar';
import { Box, Divider, IconButton, styled } from '@mui/material';
import ChatContent from '../../Messenger/ChatContent';


const RootWrapper = styled(Box)(
  ({ theme }) => `
       height: calc(100vh - ${theme.header.height});
       display: flex;
`
);


const ChatWindow = styled(Box)(
  () => `
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
`
);

const ChatTopBar = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.white[100]};
        border-bottom: ${theme.colors.alpha.black[10]} solid 1px;
        padding: ${theme.spacing(2)};
        align-items: center;
`
);

const IconButtonToggle = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  background: ${theme.colors.alpha.white[100]};
`
);


function BeckGPTAC() {
  return (
    <>
      <RootWrapper>
        <ChatWindow>
          <ChatContent />
        </ChatWindow>
      </RootWrapper>
    </>
  );
}


export const BeckGPT = observer(BeckGPTAC);
