import { Box, Card, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

function PageHeader({ toggleDarkMode, isDarkMode }) {

  return (
    <Grid>
      <Card
        style={{ textAlignLast: 'center', marginBottom: '25px',  marginTop: '25px' }}>
        <Grid display={'flex'}>
          <Box>
            <img
              src={isDarkMode ? '/static/images/mesh/dark-logo.png' : '/static/images/mesh/logo.png'}
              alt="IMAGE"
              style={{ height: '100px'}}
            />
          </Box>
          <Box flex={8}>
            <Typography variant="h3" component="h3" gutterBottom style={{ marginTop: '5px' }}>
              Welcome to the new Consultants Cockpit!
            </Typography>
            <Typography variant="subtitle2">
              Verschaffe dir einen schnellen Überblick über:
            </Typography>
            <Typography variant="subtitle2">
              die Technologie unser Kunden, aktuelle Regulatorik oder die neusten Nachrichten
            </Typography>
          </Box>
          <Box flex={1} display="flex" justifyContent="flex-end" alignItems="center" style={{marginRight: '50px'}}>
            <IconButton onClick={toggleDarkMode} color="inherit">
              {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
          </Box>
        </Grid>
      </Card>
    </Grid>
  );
}

export default PageHeader;
