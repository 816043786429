import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { Api } from '../utils/ApiService';
import {
  ChatMessage,
  CompanyWatch,
  Important,
  Job,
  News,
  NewsItem,
  Stock,
  StockApiResponse,
  Watch,
  X1F
} from './entity';
import moment from 'moment/moment';
import { Helper } from '../utils/helper';

export class Store {

  constructor() {
    makeAutoObservable(this);
  }

  helper = new Helper();

  endpointNews = this.helper.getBaseUrl + `/api/news`;

  endpointJobs = this.helper.getBaseUrl + `/api/jobs`;

  endpointWatch = this.helper.getBaseUrl + `/api/topic`;

  endpointCompany = this.helper.getBaseUrl + `/api/company`;

  endpointStock = this.helper.getBaseUrl + `/api/stock`;

  endpointChat = this.helper.getBaseUrl + `/api/chat`;

  api = new Api();

  jobs: Job[] = [];

  news: News[] = [];

  filteredJobs: Job[] = [];

  filteredNews: News[] = [];

  stockNews: News[] = [];

  jobItem: string[] = [];

  newsItem: NewsItem[] = [];

  trends: string = '';

  watch: Watch;

  companyItem: string = '';

  companies: string[] = [];

  x1f: X1F[] = [];

  companyWatch: CompanyWatch;

  selectedCompany: string = '';

  trendTopicsName: string[] = [];

  hotTopicsName: string[] = [];

  total: string = '0';

  version: string = '';

  important_hints: Important[] = [];

  hints: Stock[] = [];

  stockData: StockApiResponse;

  messages: ChatMessage[] = [];


  getJobs = async (item: string) => {
    const response = await this.api.fetch({
      endpoint: `${this.endpointJobs + '/' + item.replace('/', ' ')}`,
      method: 'GET'
    });

    if (response.length == 0) {
      return;
    }

    runInAction(() => {
      this.jobs = Object.values(toJS(response));
      this.filteredJobs = Object.values(toJS(response));
    });
  };

  getNews = async (item: string) => {
    const response = await this.api.fetch({
      endpoint: `${this.endpointNews + '/' + item}`,
      method: 'GET'
    });

    if (response.length == 0) {
      return;
    }

    runInAction(() => {
      this.news = Object.values(toJS(
        response.sort(function(a: News, b: News) {
          return moment(b.published_date).diff(moment(a.published_date));
        })
      ));
      this.filteredNews = this.news;
    });
  };

  getNewsItem = async () => {
    const response = await this.api.fetch({
      endpoint: `${this.endpointNews + `/items`}`,
      method: 'GET'
    });
    runInAction(() => {
      this.newsItem = response;
    });

  };

  getJobItem = async () => {
    const response = await this.api.fetch({
      endpoint: `${this.endpointJobs + `/items`}`,
      method: 'GET'
    });

    runInAction(() => {
      this.jobItem = response;
    });
  };


  getJobTotal = async () => {
    const response = await this.api.fetch({
      endpoint: `${this.endpointJobs + `/total`}`,
      method: 'GET'
    });

    runInAction(() => {
      this.total = response;
    });
  };

  getTopics = async () => {
    const response = await this.api.fetch({
      endpoint: `${this.endpointJobs + `/topics`}`,
      method: 'GET'
    });

    runInAction(() => {
      this.trendTopicsName = response['trend'];
      this.hotTopicsName = response['hot'];
    });
  };


  async getTrend(item) {
    const response = await this.api.fetch({
      endpoint: `${this.endpointNews + '/trend/' + item}`,
      method: 'GET'
    });
    runInAction(() => {
      this.trends = response;
    });
  }

  getWatch = async (item: string) => {
    const response = await this.api.fetch({
      endpoint: `${this.endpointWatch + '/info/' + item}`,
      method: 'GET'
    });

    if (response.length == 0) {
      return;
    }

    runInAction(() => {
      this.companyItem = item;
      this.watch = response;
    });
  };

  getCompanies = async () => {
    const response = await this.api.fetch({
      endpoint: `${this.endpointCompany + '/list'}`,
      method: 'GET'
    });

    if (response.length == 0) {
      return;
    }

    runInAction(() => {
      this.companies = response.companies;
    });
  };

  getX1FCompanies = async () => {
    const response = await this.api.fetch({
      endpoint: `${this.endpointCompany + '/x1f'}`,
      method: 'GET'
    });

    if (response.length == 0) {
      return;
    }

    runInAction(() => {
      this.x1f = response;
    });
  };


  getCompanyInfo = async (company: string) => {
    const response = await this.api.fetch({
      endpoint: `${this.endpointCompany + '/' + company}`,
      method: 'GET'
    });

    if (response.length == 0) {
      return;
    }

    runInAction(() => {
      this.companyWatch = response;
      this.selectedCompany = company;
    });
  };

  async getVersion() {
    const response = await this.api.fetch({
      endpoint: `${this.helper.getBaseUrl}`,
      method: 'GET'
    });

    if (response.length == 0) {
      return;
    }

    runInAction(() => {
      this.version = response.version;
    });
  };


  async getHints() {
    const response = await this.api.fetch({
      endpoint: `${this.endpointStock}`,
      method: 'GET'
    });

    if (response.length == 0) {
      return;
    }

    runInAction(() => {
      this.hints = response;
    });
  };

  async getStockNews(stock) {
    const response = await this.api.fetch({
      endpoint: `${this.endpointStock + '/news/' + stock}`,
      method: 'GET'
    });

    if (response.length == 0) {
      return;
    }

    runInAction(() => {
      this.stockNews = response;
    });
  };

  async getImportantHints() {
    const response = await this.api.fetch({
      endpoint: `${this.endpointStock + '/important'}`,
      method: 'GET'
    });

    if (response.length == 0) {
      return;
    }

    runInAction(() => {
      this.important_hints = response;
    });
  };

  async getStockTimeSerie(name: string) {
    const response = await this.api.fetch({
      endpoint: `${this.endpointStock + '/' + name}`,
      method: 'GET'
    });

    if (response.length == 0) {
      this.stockData = null;
    }

    runInAction(() => {
      this.stockData = response;
    });
  };

  async chat() {
    const gpt_response = await this.api.fetch({
      endpoint: `${this.endpointChat}`,
      method: 'POST',
      body: JSON.stringify({ 'messages': this.messages })
    });

    if (gpt_response.length == 0) {
      return;
    }

    runInAction(() => {
      this.messages.push(gpt_response);
    });
  };

}
