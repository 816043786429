import { Box, Card, Grid } from '@mui/material';
import { useDependency } from '../../../injections';
import { Controller } from '../../../mesh/controller';
import { Job } from '../../../mesh/entity';
import { observer } from 'mobx-react';
import { CategoryScale, Chart as ChartJS } from 'chart.js/auto';
import { Bubble } from 'react-chartjs-2';
import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Helper } from '../../../utils/helper';

const helper = new Helper();

function createXForBubble() {
  return ['', 'Entwicklung', 'Zahlungsverkehr', 'Wertpapierhandel', 'Business Analyse', 'Testing'
  ];
}

function createDataSet(jobs: Job[]): { x: string, y: string, r: number }[] {
  let result: { x: string, y: string, r: number }[] = [];
  let c = '';
  jobs.forEach((job) => {

    if (helper.banken.includes(job.company.name)) {
      c = job.company.name;
    } else {
      c = 'Sparkasse';
    }

    if (job.hot_hits != null) {
      job.hot_hits.replaceAll('"', '').slice(1, -1).split(',')
        .forEach((topic) => {
          const existingEntry = result.find(entry => entry.x === topic && entry.y === c);
          if (existingEntry) {
            existingEntry.r += 1;
          } else {
            result.push({ x: topic, y: c, r: 1 });
          }
        });
    }

  });

  result.forEach((entry) => {
    entry.r = Math.sqrt(entry.r) * 1.5;
  });
  return result;
}

function MeshStatsAC() {
  ChartJS.register(CategoryScale);
  const c = useDependency<Controller>(Controller);

  useEffect(() => {
    if (c.store.news.length === 0) {
      c.fetchNewsItems();
      c.fetchNews('latest');
    }
  }, [c.store.news]);

  useEffect(() => {
    if (c.store.trendTopicsName.length === 0) {
      c.fetchTopicLists();
    }
  }, [c.store.trendTopicsName]);


  const data = {
    datasets: [
      {
        label: 'Job Datensatz',
        data: createDataSet(c.store.filteredJobs),
        backgroundColor: 'rgba(85,105,255)'
      }
    ]
  };
  const isDarkMode = document.body.classList.contains('dark-mode');

  const y_axis_labels = helper.banken;
  const x_axis_labels = createXForBubble();
  const options: any = {
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            const dataIndexY = context.parsed.y;
            const yAxisLabel = y_axis_labels[dataIndexY];

            const dataPoint = context.dataset.data[context.dataIndex];
            const radius = dataPoint.r;

            return `${yAxisLabel} ${Math.round(Math.pow(radius / 1.5, 2))}`;
          }
        }
      }
    },
    scales: {
      x: {
        type: 'category',
        labels: x_axis_labels,
        ticks: {
          autoSkip: false,
          color: isDarkMode ? '#ffffff' : '#000000' // Textfarbe der X-Achsen-Beschriftungen
        }
      },
      y: {
        type: 'category',
        labels: y_axis_labels,
        ticks: {
          autoSkip: false,
          color: isDarkMode ? '#ffffff' : '#000000' // Textfarbe der X-Achsen-Beschriftungen

        }
      }
    }
  };


  return (
    <Card>
      <Grid container display="flex">
        <Box style={{ height: '400px', marginTop: '' }} flex={2} p={1}>
          <h2>Aktuell offene Stellen nach Bereich</h2>

          <Bubble style={{ height: '100%' }} data={data} options={options} />
        </Box>

        <Box flex={4} p={2}>
          {c.store.trends && (
            <ReactMarkdown>
              {c.store.trends}
            </ReactMarkdown>
          )}
        </Box>
      </Grid>
    </Card>
  );
}

export const MeshStats = observer(MeshStatsAC);
