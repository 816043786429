import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import React, { useEffect, useState } from 'react';
import Footer from 'src/components/Footer';
import { observer } from 'mobx-react';
import { MeshJobs } from './MeshJobs';
import { MeshNews } from './MeshNews';
import { MeshStats } from './MeshStatsAC';
import { MeshWatchReg } from './MeshWatchReg';
import { Box, Container, Grid, Tab, Tabs, Typography } from '@mui/material';
import { MeshCompany } from './MeshCompany';
import { MeshX1F } from './MeshX1F';
import { jwtDecode } from 'jwt-decode';
import { MeshStock } from './MeshStock';
import { BeckGPT } from './BeckGPT';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-panel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function DashboardAC() {
  const [tabValue, setTabValue] = useState(0);
  const [showMeshJobs, setShowMeshJobs] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });

  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem('darkMode', JSON.stringify(newMode));
      return newMode;
    });
  };

  useEffect(() => {
    // Dark Mode auf das body-Tag anwenden
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [isDarkMode]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.sub && decodedToken.sub.includes('full')) {
        setShowMeshJobs(true);
      }
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    localStorage.setItem('tab', newValue);
  };

  return (
    <>
      <Helmet>
        <title>Consultants Cockpit</title>
      </Helmet>
      <Container maxWidth="xl">

      <PageHeader toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />

        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
          <Grid item xs={12}>
            <MeshStats />
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="Mesh tabs"
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: 'transparent',
                    borderStyle: 'none',
                    boxShadow: 'none',
                    color: isDarkMode ? 'white' : 'black',
                  },
                }}
              >
                {showMeshJobs && <Tab label="Jobs" id="tab-0" aria-controls="tab-panel-0" />}
                <Tab label="News" id="tab-1" aria-controls="tab-panel-1" />
                <Tab label="Wertpapiere" id="tab-2" aria-controls="tab-panel-2" />
                <Tab label="Company" id="tab-3" aria-controls="tab-panel-3" />
                <Tab label="Regulatorik - Beta" id="tab-4" aria-controls="tab-panel-4" />
                <Tab label="X1F - Beta" id="tab-5" aria-controls="tab-panel-5" />
                <Tab label="Beck GPT" id="tab-6" aria-controls="tab-panel-6" />
              </Tabs>
            </Box>
            {showMeshJobs && (
              <TabPanel value={tabValue} index={0}>
                <MeshJobs />
              </TabPanel>
            )}
            <TabPanel value={tabValue} index={showMeshJobs ? 1 : 0}>
              <MeshNews />
            </TabPanel>
            <TabPanel value={tabValue} index={showMeshJobs ? 2 : 1}>
              <MeshStock />
            </TabPanel>
            <TabPanel value={tabValue} index={showMeshJobs ? 3 : 2}>
              <MeshCompany />
            </TabPanel>
            <TabPanel value={tabValue} index={showMeshJobs ? 4 : 3}>
              <MeshWatchReg />
            </TabPanel>
            <TabPanel value={tabValue} index={showMeshJobs ? 5 : 4}>
              <MeshX1F />
            </TabPanel>
            <TabPanel value={tabValue} index={showMeshJobs ? 6 : 5}>
              <BeckGPT />
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export const Dashboard = observer(DashboardAC);
