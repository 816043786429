import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { StockApiResponse } from '../../../mesh/entity';

interface CandleChartProps {
  stockData: StockApiResponse;
}

const CandleChart: React.FC<CandleChartProps> = ({ stockData }) => {
  const isDarkMode = document.body.classList.contains('dark-mode');

  if (stockData['response'] == null) {
    return <p>Keine Daten zu der Aktie</p>;
  }


  const data = Object.entries(stockData.response)
    .slice(240, 300)  // Nur die ersten 60 Einträge nehmen
    .map(([timestamp, dailyData]) => ({
      x: new Date(dailyData.tm.slice(0,10)),
      y: [
        parseFloat(String(Math.round(Number(dailyData.o) * 100) / 100)),
        parseFloat(String(Math.round(Number(dailyData.h) * 100) / 100)),
        parseFloat(String(Math.round(Number(dailyData.l) * 100) / 100)),
        parseFloat(String(Math.round(Number(dailyData.c) * 100) / 100)),
      ]
    }));

  const series = [{
    name: stockData.info.symbol,
    data: data
  }];

  const options: ApexOptions = {
    chart: {
      type: 'candlestick',
      height: 350
    },
    xaxis: {
      type: 'datetime',
      labels: {
        style: {
          colors: isDarkMode ? '#f5f5f5' : '#333'  // Achsenbeschriftung für Dark/Light Mode
        }
      },
      axisBorder: {
        color: isDarkMode ? '#f5f5f5' : '#333'  // Achsenlinie für Dark/Light Mode
      },
      axisTicks: {
        color: isDarkMode ? '#f5f5f5' : '#333'  // Achsen-Ticks für Dark/Light Mode
      }
    },
    yaxis: {
      tooltip: {
        enabled: true,
      },
      labels: {
        style: {
          colors: isDarkMode ? '#f5f5f5' : '#333'  // Achsenbeschriftung für Dark/Light Mode
        }
      }
    },
    tooltip: {
      theme: isDarkMode ? 'dark' : 'light',  // Tooltip Farben für Dark/Light Mode
      style: {
        fontSize: '12px',
        fontFamily: 'Arial, sans-serif'
      },
      x: {
        show: true,
        format: 'dd MMM yyyy',
        formatter: undefined
      },
      y: {
        formatter: (value) => value.toFixed(2),
        title: {
          formatter: (seriesName) => seriesName,
        }
      },
      marker: {
        show: true,
      },
      fixed: {
        enabled: false,
      },
      onDatasetHover: {
        highlightDataSeries: true,
      },
      shared: true
    },
    theme: {
      mode: isDarkMode ? 'dark' : 'light'  // Chart-internes Thema
    }
  };


  return (
    <div id="chart">
      <Chart options={options} series={series} type="candlestick" height={350} />
    </div>
  );
};

export default CandleChart;